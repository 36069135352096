/* eslint-disable react-hooks/exhaustive-deps, react/no-unused-prop-types */
import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFetch } from '../Hooks';
import { useESContext, usePreparedQuery } from './hooks';
import {
  applyManualSearchAction,
  historyUpdate,
  mapConfigurationFields,
  parseParams,
} from './utils/EnhancedSearchUtils';
import {
  ESListView,
  ESError,
  ExportModal,
  FilterContainer,
} from './components';
import { Spinner } from '../Spinner';
import {
  EnhancedSearchProps,
  Configuration,
  LVSearchResponse,
  MappedConfiguration,
} from '../types/EnhancedSearch';
import { defaultMainState, defaultQueryState } from './constants';
import useAdjustedFields from './hooks/useAdjustedFields';

export const EnhancedSearchComponent = forwardRef(
  (
    {
      actions = [],
      csvExportLimit = 10000,
      defaultQuery = {},
      disableDefaultExportAction,
      documentExportData = {
        buyerId: '',
        userId: '',
        errorCallback: () => {},
        successCallback: () => {},
        recordType: '',
      },
      eso = '',
      enableManualSearch = false,
      excludedFields: excludedFieldsProp = [],
      excludedFieldsColumnsOnly = [],
      includedFields = [],
      requiredDataFields = [],
      extraColumns = [],
      fieldOverrides = {},
      isResizable = true,
      listViewProps = {},
      noResultsText = null,
      onDataChange = () => {},
      onQueryChange = () => {},
      getTotalResults = () => {},
      showColumnConfig = true,
      tooltips = [],
      uri = '',
      uriParsing = true,
    }: EnhancedSearchProps,
    ref
  ) => {
    const location = useLocation();
    const navigate = useNavigate();
    const context = useESContext();
    const {
      configuration,
      mainState,
      query,
      queryChangeReason,
      setMainState,
      setQuery,
      handleFetchError,
    } = context;
    const preparedQuery = usePreparedQuery(query, {
      extraColumns,
      includedFields,
      requiredDataFields,
    });
    const excludedFields = [
      ...excludedFieldsProp,
      ...includedFields.map(it => it.field),
    ];
    useAdjustedFields({ fieldOverrides, excludedFields });

    /* -----> HTTP <----- */
    useFetch(`${uri}/configuration`, {
      defaultOnSuccess: (configurationData: Configuration) => {
        /* Based on the configuration data, maps the current fields */
        const configuration: MappedConfiguration = mapConfigurationFields(
          { excludedFields, fieldOverrides },
          configurationData
        );

        context.setConfiguration(configuration);
        if (enableManualSearch) {
          setMainState({ ...defaultMainState, pageLoading: false });
        }
      },
      defaultOnError: configurationError => {
        handleFetchError(configurationError);
      },
    });

    const { data: searchData, doFetch: doPerformSearch } = useFetch(
      `${uri}/search`,
      {
        defaultMethod: 'POST',
        skipToLastRequest: true,
      }
    );

    /* -----> Handlers <----- */
    /* Performs a search HTTP call with the current query and configuration */
    const performSearch = () => {
      if (!query || !configuration) return;

      setMainState({ ...mainState, searchLoading: true });

      doPerformSearch({
        data: preparedQuery,
        onSuccess: (searchResult: LVSearchResponse) => {
          if (onDataChange) onDataChange(searchResult.results);

          /* If total count is disabled, check if it's the end of the list. */
          if (
            !preparedQuery?.pagination?.includeTotalCount &&
            searchResult.pagination.first &&
            !searchResult.results.length
          ) {
            setMainState({
              ...defaultMainState,
              nextButtonDisabled: true,
              pageLoading: false,
            });
          } else {
            setMainState({ ...defaultMainState, pageLoading: false });
          }

          getTotalResults(searchResult.pagination?.totalCount);
        },
        onError: searchError => {
          handleFetchError(searchError);
        },
      });
    };

    /* -----> Effects <----- */
    useImperativeHandle(
      ref,
      () => ({
        performSearch,
      }),
      [performSearch]
    );

    /* Handles the initial URI parameters parse */
    useEffect(() => {
      const queryParams = location.search
        ? parseParams(location.search)
        : defaultQuery;

      onQueryChange(queryParams);
      setQuery({ ...query, ...queryParams });
    }, []);

    /* >> Search trigger << */
    useEffect(() => {
      if (query && !Object.is(query, defaultQueryState)) {
        if (uriParsing) {
          historyUpdate(
            query,
            // defaultQueryState - since some attributes aren't always passed in the defaultQuery
            { ...defaultQueryState, ...defaultQuery },
            location,
            navigate
          );
        }

        if (enableManualSearch) {
          if (queryChangeReason !== 'FILTER_CHANGE') performSearch();
        } else {
          performSearch();
        }
      }
    }, [query, configuration]);

    /* -----> View <----- */
    /* Error Stack Trace */
    if (mainState.error) {
      return <ESError />;
    }

    /* Loading Screen */
    if (mainState.pageLoading) {
      return <Spinner hasDelay={false} />;
    }

    /* Enhanced Search */
    return (
      <div>
        <FilterContainer
          esaUri={uri}
          excludedFields={excludedFields}
          onQueryChange={onQueryChange}
          tooltips={tooltips}
        />
        <ESListView
          actions={
            enableManualSearch
              ? applyManualSearchAction(actions, performSearch)
              : actions
          }
          disableDefaultExportAction={disableDefaultExportAction}
          excludedFieldsColumnsOnly={excludedFieldsColumnsOnly}
          extraColumns={extraColumns}
          isResizable={isResizable}
          listViewProps={listViewProps}
          noResultsText={noResultsText}
          onActionComplete={performSearch}
          onQueryChange={onQueryChange}
          searchResult={searchData}
          showColumnConfig={showColumnConfig}
        />
        <ExportModal
          csvExportLimit={csvExportLimit}
          documentExportData={documentExportData}
          esaUri={uri}
          eso={eso}
          preparedQuery={preparedQuery}
          excludedFieldsColumnsOnly={excludedFieldsColumnsOnly}
        />
      </div>
    );
  }
);
