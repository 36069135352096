export const getColumnWidth = (
  widths: string[] | number[] | null,
  idx: number
) => {
  if (widths === null) return {};
  if (typeof widths[idx] === 'number')
    return { width: `${widths[idx]}px`, minWidth: `${widths[idx]}px` };
  if (typeof widths[idx] !== 'string') return {};
  return { width: widths[idx], minWidth: widths[idx] };
};
