import React, { useState } from 'react';
import {
  findDefaultNumberFilterOperator,
  isEndValueAvailable,
  isEndValueEnabled,
  isStartValueEnabled,
} from '../utils/NumberFilterUtils';
import { useLibNSTranslation } from '../../utils/i18nUtils';
import { cx } from '../../utils';
import { Radio } from '../../Radio';
import SelectInput from './SelectInput';
import { Text } from '../../Text';
import { useDebounce } from '../../Hooks/useDebounce';
import { NumberFilterProps } from '../../types/EnhancedSearch';
import { TERNARY_OPERATORS, UNARY_OPERATORS } from '../constants';

export default function NumberFilter({
  field,
  filter,
  onFilterChange,
  tooltipText,
}: NumberFilterProps) {
  const { t } = useLibNSTranslation();
  const debouncedOnFilterChange = useDebounce(onFilterChange, 250);

  const [startValue, setStartValue] = useState(filter.values?.[0] || '');
  const [endValue, setEndValue] = useState(filter.values?.[1] || '');
  const [operator, setOperator] = useState(
    filter.operator || findDefaultNumberFilterOperator(field)
  );


  /* -----> Utils <----- */
  const fireFilterChange = change => {
    const start = change.newStartValue 
    const end = change.newEndValue 

    let currentOperator = change.operator || operator;

    if (start && !end && currentOperator === 'BETWEEN') {
      currentOperator = 'GREATER_THAN_EQUAL';
    }

    let values: [] | Number[];

    if (UNARY_OPERATORS.includes(currentOperator)) {
      values = [];
    } else if (TERNARY_OPERATORS.includes(currentOperator)) {
      values = [start, end];
    } else {
      values = [start];
    }

    debouncedOnFilterChange({ ...filter, operator: currentOperator, values });
  };

  /* -----> Handlers <----- */
  const onOperatorChange = event => {
    setOperator(event.target.value);
    fireFilterChange({ operator: event.target.value });
  };

  const onStartValueChange = event => {
    const eventValue = event.target.value;
    const newStartValue = eventValue !== '' ? Number(eventValue) : eventValue;
    setStartValue(newStartValue);
    fireFilterChange({ newStartValue });
  };

  const onEndValueChange = event => {
    const eventValue = event.target.value;
    const newEndValue = eventValue !== '' ? Number(eventValue) : eventValue;
    setEndValue(newEndValue);
    fireFilterChange({ newEndValue });
  };

  /* -----> View <----- */
  const getSelectedContent = () => {
    let returnStr = '';

    if (startValue || !isStartValueEnabled(operator)) {
      returnStr += t(`enhancedSearch.operators.${operator}`);
    }
    if (isStartValueEnabled(operator)) {
      returnStr += ` ${startValue}`;
    }
    if (isEndValueEnabled(operator)) {
      returnStr += ` ${t('enhancedSearch.filters.and')} ${endValue}`;
    }

    return returnStr.trim() ? [returnStr] : [];
  };

  return (
    <div className="filterSelect numberFilter">
      <SelectInput
        className="numberFilter"
        fieldName={field.name}
        label={field.label}
        tooltipText={tooltipText}
        selectedContent={getSelectedContent()}
        content={
          <div className="filter-content">
            <div className="numberInput">
              <Text
                data-testid="start-value"
                disabled={!isStartValueEnabled(operator)}
                onChange={onStartValueChange}
                type="number"
                value={
                  isStartValueEnabled(operator)
                    ? startValue
                    : t('enhancedSearch.filters.na')
                }
              />
              {isEndValueAvailable(field) ? (
                <>
                  <span
                    className={cx(!isEndValueEnabled(operator) && 'disabled')}
                  >
                    {t('enhancedSearch.filters.and')}
                  </span>
                  <Text
                    data-testid="end-value"
                    disabled={!isEndValueEnabled(operator)}
                    onChange={onEndValueChange}
                    type="number"
                    value={
                      isEndValueEnabled(operator)
                        ? endValue
                        : t('enhancedSearch.filters.na')
                    }
                  />
                </>
              ) : null}
            </div>
            <div className="advancedSearchOperators">
              {field.supportedOperators?.map(supportedOperator => (
                <Radio
                  data-testid={supportedOperator}
                  key={supportedOperator}
                  value={supportedOperator}
                  checked={supportedOperator === operator}
                  onChange={onOperatorChange}
                >
                  {t(`enhancedSearch.operators.${supportedOperator}`)}
                </Radio>
              ))}
            </div>
          </div>
        }
      />
    </div>
  );
}