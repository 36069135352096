import React from 'react';
import { DetailsV2Props } from '../types';
import { getColumnWidth } from './utils';

export default function DetailsV2({
  data,
  gap = 20,
  widths = null,
}: DetailsV2Props) {
  return (
    <div className="detailsV2-container" style={{ columnGap: gap }}>
      {data.map((columnData, idx) => (
        <div
          className="details-column"
          key={`details-column-${idx}`}
          data-testid={`details-column-${idx}`}
          style={{ ...getColumnWidth(widths, idx) }}
        >
          {columnData.map(({ label, value }) => (
            <div key={label} className="details-item">
              <div className="details-label">{label}</div>
              <div className="details-value">{value}</div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
