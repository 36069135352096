import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import * as locales from 'date-fns/locale';
import { cx } from '../utils';
import { useLibNSTranslation } from '../utils/i18nUtils';
import { DatePickerProps } from '../types';
import { CustomValidation, RequiredValidation, validate } from '../Validation';
import { localeMap } from './constants';
import { getTargetDate, shouldSwitchCalendar } from './utils';

const DateRangePicker = forwardRef(
  (
    {
      __triggerFormValidation = () => {},
      disabled = false,
      disabledStartDate = false,
      enableAutoCalendarSwitch = false,
      endDate = null,
      endError = false,
      endLabel,
      id = '',
      localeOverride = '',
      onChangeDate = () => {},
      preSelectedInterval = null,
      required = false,
      startDate = null,
      startError = false,
      startLabel,
      validation = false,
      weekdayNameHeaderLength = 1,
    }: DatePickerProps,
    ref
  ) => {
    const { i18n, t } = useLibNSTranslation();
    const [start, setStart] = useState<DatePickerProps['startDate']>(
      startDate ? new Date(startDate) : null
    );
    const [end, setEnd] = useState<DatePickerProps['endDate']>(
      endDate ? new Date(endDate) : null
    );
    const [isTouched, setIsTouched] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<false | string>(false);

    const touchedRef = useRef(false);
    const endPickerRef = useRef<any>(null);
    const startPickerRef = useRef<any>(null);
    const errorRef = useRef<false | string>(false);
    const locale = localeOverride || i18n.language;
    const dateFormat = locale === 'en-US' ? 'M/d/yyyy' : 'yyyy/MM/dd';

    /* -----> Utils <----- */

    function updateTouched(touched: boolean) {
      setIsTouched(touched);
      touchedRef.current = touched;
    }

    function updateError(error: false | string) {
      setErrorMessage(error);
      errorRef.current = error;
    }

    function validateRange(vStart = start, vEnd = end) {
      const validationError = validate(
        { start: vStart, end: vEnd },
        validation,
        {}
      );

      updateError(validationError);
      __triggerFormValidation(id);
    }

    const componentHasError = (checkRefs = false) => {
      if (checkRefs) {
        return (
          (required && (!start || !end) && touchedRef.current) ||
          (errorRef.current && (start || end))
        );
      }

      return (
        (required && (!start || !end) && isTouched) ||
        (errorMessage && (start || end))
      );
    };

    const switchCalendar = (way: 'startToEnd' | 'endToStart') => {
      const switchDate = way === 'startToEnd' ? end : start;

      if (switchDate === null) {
        const picker = way === 'startToEnd' ? endPickerRef : startPickerRef;

        picker.current.setOpen(true);
      }
    };

    /* -----> Handlers <----- */

    const handleStartChange = (newStartDate: Date) => {
      updateTouched(true);
      onChangeDate([newStartDate, end as Date]);

      setStart(newStartDate);
      validateRange(newStartDate);

      if (shouldSwitchCalendar(enableAutoCalendarSwitch, 'startToEnd')) {
        switchCalendar('startToEnd');
      }
    };

    const handleEndChange = (newEndDate: Date) => {
      updateTouched(true);
      onChangeDate([start as Date, newEndDate]);

      setEnd(newEndDate);
      validateRange(start, newEndDate);

      if (shouldSwitchCalendar(enableAutoCalendarSwitch, 'endToStart')) {
        switchCalendar('endToStart');
      }
    };

    const onBlurHandler = () => {
      updateTouched(true);
    };

    /* -----> Effects <----- */

    useEffect(() => {
      // Localize names of days/months
      const translationString = localeMap[locale] || locale;
      registerLocale('translations', locales[translationString]);
      setDefaultLocale('translations');
    });

    useEffect(() => {
      if (startDate) setStart(new Date(startDate));
      if (endDate) setEnd(new Date(endDate));
      validateRange(startDate, endDate);
    }, [startDate, endDate]);

    useEffect(() => {
      if (preSelectedInterval && ((!start && end) || (start && !end))) {
        const targetPicker = start === null ? end : start;
        const targetRef = start === null ? startPickerRef : endPickerRef;
        const targetDate = getTargetDate(
          targetPicker,
          preSelectedInterval,
          start === null ? 'endToStart' : 'startToEnd'
        );

        targetRef.current.setPreSelection(targetDate);
      }
    }, [start, end, preSelectedInterval]);

    useImperativeHandle(ref, () => ({
      name: id,
      value: { start, end },
      clearState: () => {
        setStart(null);
        setEnd(null);
        updateTouched(false);
        updateError(false);
      },
      hasValidationError: () => componentHasError(true),
      onSubmitValidation: () => {
        updateTouched(true);
      },
    }));

    return (
      <div
        data-testid="date-range-picker"
        className="date-range-picker date-range"
      >
        <div className="date-picker-wrapper start">
          <label
            className={cx({
              'has-error': startError || (!start && componentHasError()),
            })}
            htmlFor={cx('datePickerStart', id)}
          >
            <strong className={cx({ 'required-label': required })}>
              {startLabel || t('enhancedSearch.date.startDate')}
            </strong>
          </label>
          <DatePicker
            ref={startPickerRef}
            autoComplete="off"
            dateFormat={dateFormat}
            disabled={disabled || disabledStartDate}
            endDate={end}
            maxDate={end}
            fixedHeight
            id={cx('datePickerStart', id)}
            onBlur={onBlurHandler}
            onChange={handleStartChange}
            onClickOutside={onBlurHandler}
            selected={start}
            selectsStart
            startDate={start}
            shouldCloseOnSelect
            formatWeekDay={nameOfDay =>
              nameOfDay.substr(
                0,
                weekdayNameHeaderLength > 3 ? 3 : weekdayNameHeaderLength
              )
            }
          />
        </div>
        <div className="date-picker-wrapper end">
          <label
            className={cx({
              'has-error': endError || (!end && componentHasError()),
            })}
            htmlFor={cx('datePickerEnd', id)}
          >
            <strong className={cx({ 'required-label': required })}>
              {endLabel || t('enhancedSearch.date.endDate')}
            </strong>
          </label>
          <DatePicker
            ref={endPickerRef}
            autoComplete="off"
            dateFormat={dateFormat}
            disabled={disabled}
            endDate={end}
            fixedHeight
            id={cx('datePickerEnd', id)}
            minDate={start}
            onBlur={onBlurHandler}
            onClickOutside={onBlurHandler}
            onChange={handleEndChange}
            selected={end}
            selectsEnd
            startDate={start}
            formatWeekDay={nameOfDay =>
              nameOfDay.substr(
                0,
                weekdayNameHeaderLength > 3 ? 3 : weekdayNameHeaderLength
              )
            }
          />
        </div>
        <div className="validation-error-wrapper flex">
          <RequiredValidation
            value={Boolean(start && end)}
            required={required}
            isTouched={isTouched}
          />
          <CustomValidation
            errorMessage={errorMessage}
            condition={Boolean(errorMessage && (start || end))}
          />
        </div>
      </div>
    );
  }
);

DateRangePicker.defaultProps = {
  __hasRef: true,
};

export default DateRangePicker;
