/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { omit } from 'lodash';
import { Branding } from '../Branding';
import { CheckboxIcon } from '../Icons';
import { colors } from '../theme';
import { cx } from '../utils';
import { CheckboxProps } from '../types';
import { AutomatedValidation, validate } from '../Validation';

function getInputStyles({
  disabled,
  hover,
  focus,
  checked,
}: {
  disabled: boolean;
  hover: boolean;
  focus: boolean;
  checked: boolean;
}) {
  if (disabled) {
    return {};
  }

  const primary = Branding.fetchColor('primary').hex();

  if (checked) {
    return {
      borderColor: primary,
      backgroundColor: primary,
    };
  }

  if (hover || focus) {
    return {
      borderColor: primary,
      backgroundColor: `${colors.white}`,
    };
  }

  return {};
}

const Checkbox = forwardRef(
  (
    {
      __triggerFormValidation = () => {},
      automated = false,
      checked = false,
      children = null,
      className = '',
      disabled = false,
      id = '',
      inline = false,
      onBlur = () => null,
      onChange = () => null,
      onFocus = () => null,
      onKeyDown = () => null,
      onMouseDown = () => null,
      onMouseOut = () => null,
      onMouseOver = () => null,
      onMouseUp = () => null,
      required = false,
      validation = false,
      validationState = null,
      ...otherProps
    }: CheckboxProps,
    componentRef
  ) => {
    const [value, setValue] = useState(checked);
    const [isTouched, setIsTouched] = useState(false);
    const [errorMessage, setErrorMessage] = useState<false | string>(false);

    const [hover, setHover] = useState(false);
    const [focus, setFocus] = useState(false);

    const ref = useRef<HTMLLabelElement>(null);
    const touchedRef = useRef(false);
    const errorRef = useRef(false);
    const currentValue = automated ? value : checked;

    function updateTouched(touched) {
      setIsTouched(touched);
      touchedRef.current = touched;
    }

    function updateError(error) {
      setErrorMessage(error);
      errorRef.current = error;
    }

    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        ref.current?.contains(e.target as Node) &&
        e.code === 'Space' &&
        e.key === ' ' &&
        onKeyDown &&
        !disabled
      ) {
        onKeyDown(e);
        updateTouched(true);
      }
    };

    const handleBlur = (e: React.FocusEvent<HTMLLabelElement>) => {
      e.persist();
      setFocus(false);
      onBlur(e);
    };

    const handleFocus = (e: React.FocusEvent<HTMLLabelElement>) => {
      e.persist();
      setFocus(true);
      onFocus(e);
    };

    const handleMouseDown = (e: React.MouseEvent<HTMLLabelElement>) => {
      e.persist();
      onMouseDown(e);
    };

    const handleMouseOut = (e: React.MouseEvent<HTMLLabelElement>) => {
      e.persist();
      setHover(false);
      onMouseOut(e);
    };

    const handleMouseOver = (e: React.MouseEvent<HTMLLabelElement>) => {
      e.persist();
      setHover(true);
      onMouseOver(e);
    };

    const handleMouseUp = (e: React.MouseEvent<HTMLLabelElement>) => {
      e.persist();
      onMouseUp(e);
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (disabled) return;
      e.persist();
      onChange(e);
      updateTouched(true);

      setValue(!value);

      const validationError = validate(!value, validation, {}); // TODO: Add list of predefined validations
      updateError(validationError);
      __triggerFormValidation(id);
    };

    const getIconStyles = ({
      isDisabled,
      isChecked,
    }: {
      isDisabled: boolean;
      isChecked: boolean;
    }) => {
      if (isDisabled) {
        return {};
      }

      if (isChecked) {
        return {
          color: 'transparent',
        };
      }

      return {};
    };

    useEffect(() => {
      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(componentRef, () => ({
      name: id,
      value,
      clearState: () => {
        setValue(false);
        updateTouched(false);
        updateError(false);
      },
      hasValidationError: () =>
        (required && touchedRef.current && !currentValue) || errorRef.current,
      onSubmitValidation: () => {
        updateTouched(true);
      },
    }));

    return (
      <div className="checkbox-container">
        <label
          className={cx(
            'tau-checkbox',
            'tau-input',
            className,
            validationState ? `has-${validationState}` : null,
            { inline, checked: currentValue, disabled },
            { 'required-label': required }
          )}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onMouseDown={handleMouseDown}
          onMouseOut={handleMouseOut}
          onMouseOver={handleMouseOver}
          onMouseUp={handleMouseUp}
          ref={ref}
        >
          <input
            type="checkbox"
            checked={currentValue}
            aria-checked={currentValue}
            data-testid="checkbox"
            id={id}
            {...omit(
              otherProps,
              '__hasRef',
              'onBlur',
              'onChange',
              'onFocus',
              'onMouseDown',
              'onMouseOut',
              'onMouseOver',
              'onMouseUp'
            )}
            onChange={handleOnChange}
          />
          <span
            className="input"
            style={getInputStyles({
              disabled: disabled || validationState !== null,
              hover,
              focus,
              checked: currentValue,
            })}
          >
            <span
              className="icon"
              style={getIconStyles({
                isDisabled: disabled || validationState !== null,
                isChecked: currentValue,
              })}
            >
              <CheckboxIcon />
            </span>
          </span>
          <span className="text">{children}</span>
        </label>
        <AutomatedValidation
          errorMessage={errorMessage}
          isTouched={isTouched}
          required={required}
          value={currentValue}
        />
      </div>
    );
  }
);

Checkbox.defaultProps = {
  __hasRef: true,
};

export { Checkbox, getInputStyles };
