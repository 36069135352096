import { AutoCalendarSwitch } from '../../types';

export const shouldSwitchCalendar = (
  option: AutoCalendarSwitch,
  direction: 'startToEnd' | 'endToStart'
) => {
  if (typeof option === 'boolean') return option;
  switch (direction) {
    case 'startToEnd':
      return option.startToEnd ?? false;
    case 'endToStart':
      return option.endToStart ?? false;
  }
};

export const getTargetDate = (
  currentDate,
  preSelectedInterval,
  direction: 'startToEnd' | 'endToStart'
) => {
  if (typeof preSelectedInterval === 'number') {
    let targetDate = currentDate?.getTime();
    const interval = preSelectedInterval * 86400000; // 86400000 = 1 day in milliseconds

    if (direction === 'startToEnd') {
      targetDate += interval;
    } else {
      targetDate -= interval;
    }

    const currentOffset = currentDate?.getTimezoneOffset();
    const targetOffset = new Date(targetDate).getTimezoneOffset();

    if (currentOffset !== targetOffset) {
      const difference = Math.abs(currentOffset) - Math.abs(targetOffset);
      targetDate += difference * 60000; // 60000 = 1 minute in milliseconds
    }

    return new Date(targetDate);
  }

  return new Date(preSelectedInterval);
};
