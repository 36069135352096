import React from 'react';
import { Button } from '../../Button';
import { Spinner } from '../../Spinner';
import { cx } from '../../utils';

export const generateButtons = ({ buttons }) =>
  buttons
    ? buttons.reduce(
        (acc, buttonProps, idx) => {
          const { position, label, onClick, spinning, props } = buttonProps;

          const spinnerTheme = props?.theme
            ? props?.theme === 'light'
              ? 'dark'
              : 'light'
            : 'dark'; // default to dark theme

          const ButtonElement = (
            <Button
              key={`${label}-${idx}`}
              onClick={onClick}
              {...props}
              className={cx(props?.className, { 'is-spinning': spinning })}
            >
              {spinning ? <Spinner isButton theme={spinnerTheme} /> : null}
              <span className="name-wrapper">{label}</span>
            </Button>
          );

          if (position === 'start')
            return {
              ...acc,
              ButtonsBefore: [...acc.ButtonsBefore, ButtonElement],
            };

          return { ...acc, ButtonsAfter: [...acc.ButtonsAfter, ButtonElement] };
        },
        { ButtonsBefore: [], ButtonsAfter: [] }
      )
    : { ButtonsBefore: null, ButtonsAfter: null };
