import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import {
  compileFormData,
  componentsHaveValidationError,
  elementHasValidationError,
  generateButtons,
  triggerOnTouchErrors,
  triggerStateClear,
} from './utils';
import { cx, mergeObjects } from '../utils';
import { useAutomatedRefs, usePredefinedValidations } from '../Hooks';
import { AutomatedValidation, FormProps, FormSubmitData } from '../types';
import {
  AutomatedElements,
  DefaultFormProps,
  FormValidation,
} from '../types/Form';
import validate from '../Validation/utils/validate';
import { Spinner } from '../Spinner';

const defaultProps = {
  className: '',
  buttonContainer: {
    buttons: null,
    className: '',
    elementsBefore: null,
    elementsAfter: null,
    modalStyling: false,
  },
  clearAfterSubmit: true,
  formProps: {},
  options: { noRequiredAsterisk: false },
  submitButton: {
    additionalValidation: false,
    buttonProps: {},
    class: '',
    defaultStyle: true,
    hide: false,
    name: 'Submit',
    spinning: false,
  },
  validation: {
    submitSameData: true,
  },
};

const Form = React.forwardRef((props: FormProps, ref) => {
  const {
    buttonContainer,
    children,
    className,
    clearAfterSubmit,
    formProps,
    id,
    options,
    onSubmit,
    submitButton,
    validation,
  }: FormProps & DefaultFormProps = mergeObjects(props, defaultProps);
  const { formValidations } = usePredefinedValidations();

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [isValidating, setIsValidating] = useState<FormValidation>({
    triggered: 0,
    element: '',
  });
  const [monitoredComponents, setMonitoredComponents] = useState<string[]>([]);
  const [formError, setFormError] = useState<string | false>(false);

  const {
    elementsWithRefs: automatedChildren,
    automatedComponentsList,
    automatedComponentsObject,
  }: AutomatedElements = useAutomatedRefs(children, triggerInputValidation);
  const initialFormData = useRef<FormSubmitData>({});

  /* -----> Refs <----- */
  const formRef = useRef<HTMLFormElement>(null);

  /* -----> Utils <----- */
  const formHasErrors = (): boolean =>
    componentsHaveValidationError(automatedComponentsList);

  /* Clear the current Form Error and check its Elements for Errors */
  const checkComponentsForErrors = (): boolean => {
    setFormError(false);

    const errorComponents = Array.from(
      new Set([...monitoredComponents, isValidating.element])
    ).reduce((result, id) => {
      const hasError = elementHasValidationError(automatedComponentsObject[id]);
      return hasError ? [...result, id] : result;
    }, []);

    setMonitoredComponents(errorComponents);
    return errorComponents.length > 0;
  };

  /* Checks the current form data against the provided validations */
  const validateForm = () => {
    /* Compile the validation data */
    const formData = compileFormData(automatedComponentsList);

    const validationData = {
      submitData: formData,
      initialData: initialFormData.current,
    };

    /* Check against the provided validations */
    const formValidationError = validate(
      validationData,
      validation as { [key: string]: AutomatedValidation },
      formValidations
    );
    const returnedData = { formData, initialData: initialFormData.current };

    if (formValidationError) {
      setFormError(formValidationError);
      setSubmitButtonDisabled(true);

      return { hasValidationError: true, ...returnedData };
    }

    return { hasValidationError: false, ...returnedData };
  };

  /* Triggers checking for errors when an Input in the Form changes */
  function triggerInputValidation(element: string) {
    setIsValidating({ triggered: isValidating.triggered + 1, element });
  }

  /* -----> Handlers <----- */
  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    /* Trigger onTouch validation */
    triggerOnTouchErrors(automatedComponentsList);

    /* Abort the submission and disable the submit button if there are input errors */
    if (formHasErrors()) {
      setSubmitButtonDisabled(true);
    } else {
      /* Perform global form validation */
      const { hasValidationError, formData, initialData } = validateForm();

      /* Abort the submission if there are global form errors */
      if (!hasValidationError) {
        setFormError(false);
        onSubmit(formData, e, initialData);

        /* Clear the form after submission */
        if (clearAfterSubmit) {
          triggerStateClear(automatedComponentsList);
        }
      }
    }
  };

  /* -----> Effects <----- */
  useImperativeHandle(ref, () => ({
    clearForm: () => {
      triggerStateClear(automatedComponentsList);
    },
    formRef,
    formHasErrors,
    validateForm,
  }));

  /* Capture the initial form data */
  useEffect(() => {
    initialFormData.current = compileFormData(automatedComponentsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Initiates error checking, when an Input in the Form changes */
  useEffect(() => {
    if (isValidating.triggered) {
      setSubmitButtonDisabled(checkComponentsForErrors());
    }
  }, [isValidating.triggered]);

  /* -----> View <----- */
  const { ButtonsBefore, ButtonsAfter } = generateButtons(buttonContainer);

  return (
    <div className={cx('automation-form-wrapper', className)}>
      <form
        {...formProps}
        className={cx('tau-auto-form', className, {
          'no-required-asterisk': options?.noRequiredAsterisk,
        })}
        data-testid={id}
        id={id}
        noValidate
        onSubmit={onSubmitHandler}
        ref={formRef}
      >
        {/* ---> Child Elements <--- */}
        {automatedChildren}
        {/* ---> Error Wrapper <--- */}
        <div className={cx('tau-error-wrapper', { 'has-error': formError })}>
          {formError ? (
            <p className="tau-form-error" data-testid="tau-form-error">
              {formError}
            </p>
          ) : null}
        </div>
      </form>
      {/* ---> Button Container <--- */}
      <div
        className={cx('tau-button-container', buttonContainer.className, {
          'modal-style': buttonContainer.modalStyling,
        })}
      >
        {ButtonsBefore}
        {buttonContainer.elementsBefore}
        {!submitButton.hide ? (
          <button
            {...submitButton.buttonProps}
            type="submit"
            form={id}
            data-testid={`${id}-submit-button`}
            className={cx(
              submitButton.class,
              'tau-form-submit-button',
              { 'is-spinning': submitButton.spinning },
              { 'size-large theme-primary': submitButton.defaultStyle }
            )}
            disabled={
              submitButton.additionalValidation
                ? submitButtonDisabled && submitButton.additionalValidation()
                : submitButtonDisabled
            }
          >
            {submitButton.spinning ? <Spinner isButton /> : null}
            <span className="name-wrapper">{submitButton.name}</span>
          </button>
        ) : null}
        {buttonContainer.elementsAfter}
        {ButtonsAfter}
      </div>
    </div>
  );
});

export default Form;
