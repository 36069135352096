function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import i18next from 'i18next';
import { Branding } from '../Branding';
/**
 *
 * @param {string} type File content type
 * @param {object} [additionalTypes={}] Object holding type:string key:value pairs. In case the content type you are looking for isn't included.
 * @returns {string} Normalized Content Type String
 * @example
 * getContentType('application/json')
 */

export var getContentType = function getContentType(type) {
  var additionalTypes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var commonTypes = {
    'application/gzip': 'GZIP',
    'application/json': 'JSON',
    'application/pdf': 'PDF',
    'application/vnd.ms-excel': 'XLS',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
    'application/xml': 'XML',
    'application/zip': 'ZIP',
    'font/ttf': 'TTF',
    'font/woff': 'WOFF',
    'font/woff2': 'WOFF2',
    'image/bmp': 'BMP',
    'image/jpeg': 'JPEG',
    'image/png': 'PNG',
    'text/css': 'CSS',
    'text/csv': 'CSV',
    'text/html': 'HTML'
  };

  var contentTypes = _objectSpread(_objectSpread({}, commonTypes), additionalTypes);

  return contentTypes[type];
};
/**
 * Compares if a point is located inside a specific limit.
 * @param {number} start First limit representing the smaller of the two.
 * @param {number} end Second limit representing the larger of the tow.
 * @param {number} point Point tested against the provided limits.
 * @param {Object} bordersIncluded Configures if the borders should be included in the comparrison. Defaults to false for both borders.
 * @param {boolean} [bordersIncluded.startBorderIncluded]
 * @param {boolean} [bordersIncluded.endBorderIncluded]
 * @returns {boolean}
 */

export var isInsideRange = function isInsideRange(start, end, point) {
  var bordersIncluded = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {
    startBorderIncluded: false,
    endBorderIncluded: false
  };
  var startBorderIncluded = bordersIncluded.startBorderIncluded,
      endBorderIncluded = bordersIncluded.endBorderIncluded;
  var startCondition = startBorderIncluded ? point >= start : point > start;
  var endCondition = endBorderIncluded ? point <= end : point < end;
  return startCondition && endCondition;
};
/**
 * Deeply merges two objects, by keeping the unchanged key:value pairs in the initial object.
 * The logic applies only to nested Objects {} (objects inside objects and so on), since they have key:value relation.
 *
 * Note: Array assigned to a key will be replaced, so make sure a proper mapping is done beforehand if needed.
 *
 * @param {object} modifiedObject - Object containing your changes.
 * @param {object} defaultObject - Object containing the main information.
 *
 * @example
 * const obj1 = {
 *  arrVal: ["CHANGED"]
 *  nestedObj: { someValue: "CHANGED"}
 * }
 * const obj2 = {
 *  primVal: "some string",
 *  arrVal: [123]
 *  nestedObj: {
 *    mainValue: "main",
 *    someValue: "some"
 *  }
 * }
 * mergeObjects(obj1, obj2) returns:
 * {
 *  primVal: "some string",
 *  arrVal: [123,"CHANGED"]
 *  nestedObj: {
 *    mainValue: "main",
 *    someValue: "CHANGED"
 *  }
 * }
 *
 * @returns {object}
 */

export var mergeObjects = function mergeObjects(modifiedObject, defaultObject) {
  var keyArray = Object.keys(modifiedObject);
  var mergedObject = keyArray.reduce(function (result, key) {
    var defaultValue = defaultObject[key];
    var modifiedValue = modifiedObject[key];
    /* Array */

    if (Array.isArray(modifiedValue)) {
      /* Both are arrays -> Merge them */
      if (Array.isArray(defaultValue)) {
        return _objectSpread(_objectSpread({}, result), {}, _defineProperty({}, key, [].concat(_toConsumableArray(defaultValue), _toConsumableArray(modifiedValue))));
      }
      /* The accumulator is not an Array -> Replace it */


      return _objectSpread(_objectSpread({}, result), {}, _defineProperty({}, key, modifiedValue));
    }
    /* Primitives or Functions -> Replace them */


    if (_typeof(modifiedValue) !== 'object') {
      return _objectSpread(_objectSpread({}, result), {}, _defineProperty({}, key, modifiedValue));
    }
    /* null/undefined check */


    if (modifiedValue) {
      /* Both are objects -> Merge them */
      if (defaultValue && _typeof(defaultValue) === 'object') {
        return _objectSpread(_objectSpread({}, result), {}, _defineProperty({}, key, mergeObjects(modifiedValue, defaultValue)));
      }
      /* The accumulator is not an object -> Replace it */


      return _objectSpread(_objectSpread({}, result), {}, _defineProperty({}, key, modifiedValue));
    }

    return result;
  }, _objectSpread({}, defaultObject));
  return mergedObject;
};
/**
 * Yeap, it returns a "unique" string!
 * @returns {string}
 */

export var generateUID = function generateUID() {
  var makeAStringie = function makeAStringie() {
    return Math.random().toString(36).slice(2);
  };

  var randomString = makeAStringie() + makeAStringie() + makeAStringie();
  var dividedString = randomString.match(/.{1,6}/g).slice(0, 5);
  var thisLooksMoreLikeAnIdNow = dividedString.join('-');
  return thisLooksMoreLikeAnIdNow;
};
/**
 * Attempts to stringify a value using the JSON constructor.
 *
 * The main difference is that it applies try/catch logic and returns null instead of breaking your logic.
 *
 * @param {object} value
 * @param {any} [replacer]
 * @param {string | number} [space]
 * @returns {string | null}
 */

export function JSONStringify(value, replacer, space) {
  try {
    return JSON.stringify(value, replacer, space);
  } catch (error) {
    /* eslint-disable-next-line no-console */
    console.error("We were unable to process your value: ".concat(value));
    return null;
  }
}
export function JSONParse(value) {
  var failValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

  try {
    return JSON.parse(value);
  } catch (_unused) {
    /* eslint-disable-next-line no-console */
    console.error("We were unable to process your value: ".concat(value));
    return failValue;
  }
}
/**
 * Localizes a Date string to a time.
 *
 * @param {Date} date
 * @param {object} options - An object adjusting the output format. Corresponds to the options parameter of the Intl.DateTimeFormat() constructor.
 * @returns {string} A string representing the time portion of the given date according to language-specific conventions. If it is an invalid date it returns '--'.
 */

export function localizeTime(date, options) {
  var formattedTime;

  if (date) {
    formattedTime = new Date(date).toLocaleTimeString(i18next.language, options);
  }

  if (!formattedTime || formattedTime === 'Invalid Date') {
    return '--';
  }

  return formattedTime;
}
export var getPageTitle = function getPageTitle(location, t) {
  var branding = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : Branding;

  var _ref = location || {},
      pathname = _ref.pathname; // Take the path name, split it by '/' and '-', capitalize the first letter, and add '|' if has multiple sections


  if (location) {
    return pathname.split('/').filter(function (word) {
      return word;
    }).map(function (title) {
      return title.split('-').map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }).join(' ');
    }).join(' | ');
  }

  return t('app.title', {
    portalCompany: branding === null || branding === void 0 ? void 0 : branding.fetchDisplayName()
  });
};