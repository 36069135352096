import React, { useContext, useState } from 'react';
import { useLibNSTranslation } from '../../utils/i18nUtils';
import { Modal } from '../../Modal';
import { RequestContext } from '../../Contexts';
import { Radio } from '../../Radio';
import { ExportModalProps } from '../../types/EnhancedSearch';
import { exportToCSV } from '../utils/ExportModalUtils';
import { DEFAULT_EXPORT_SIZE } from '../constants';
import { useESContext } from '../hooks';
import { Checkbox } from '../../Checkbox';
import { cx } from '../../utils';

function ExportModal({
  csvExportLimit = 10000,
  documentExportData,
  esaUri,
  eso = '',
  excludedFieldsColumnsOnly,
  preparedQuery,
}: ExportModalProps) {
  const request = useContext(RequestContext);
  const { t, i18n } = useLibNSTranslation();

  /* -----> Context <----- */
  const context = useESContext();
  const { configuration, query } = context;
  const { listViewSelection, showModal } = context.modalState;

  /* -----> Local State & Variables <----- */
  const [isAllColumnsLoading, setIsAllColumnsLoading] = useState(false);
  const [isVisibleColumnsLoading, setIsVisibleColumnsLoading] = useState(false);
  const [exportAllColumns, setExportAllColumns] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(
    documentExportData?.confirmationCheckboxDefaultValue || false
  );

  const maxLimit = documentExportData ? DEFAULT_EXPORT_SIZE : csvExportLimit;
  const selectedCountExceedsLimit = listViewSelection.selectedCount > maxLimit;
  const limitStr =
    selectedCountExceedsLimit && maxLimit.toLocaleString(i18n.language);

  /* -----> Utils <----- *
  /* Hides the Export modal and clears the current selection */
  const closeModal = () => {
    context.setModalState({
      showModal: false,
      listViewSelection: { all: false, data: [], ids: [], selectedCount: NaN },
    });
  };

  const exportColumns = (allColumns: boolean) => {
    const columns = allColumns
      ? configuration?.fields.map(field => field.name)
      : query.select;

    if (allColumns) {
      setIsAllColumnsLoading(true);
    } else {
      setIsVisibleColumnsLoading(true);
    }

    const ids = listViewSelection.all // Are all records selected?
      ? [] // YES - Providing an empty array of ids to the endpoint returns all items
      : selectedCountExceedsLimit // NO -> Do the selected records exceed the limit?
      ? listViewSelection.ids.slice(0, maxLimit) // YES - Include only those inside the limit.
      : listViewSelection.ids; // NO

    if (documentExportData) {
      const {
        allColumnsExportOrder,
        allColumnsConfirmationExportOrder,
        buyerId,
        confirmationCheckboxExportColumns,
        confirmationCheckboxIdFilterFieldName,
        confirmationCheckboxRecordType,
        confirmationCheckboxText,
        confirmationCheckboxURL,
        fieldLabelOverrides,
        errorCallback,
        successCallback,
        userId,
        recordType,
      } = documentExportData;

      const fieldsWithOverriddenLabels = configuration?.fields?.map(field => {
        const matchingFieldList = fieldLabelOverrides?.filter(
          fieldLabelOverride => fieldLabelOverride.name === field.name
        );
        if (matchingFieldList && matchingFieldList.length > 0) {
          const updatedField = { ...field };
          updatedField.label = matchingFieldList[0].overrideLabel;
          return updatedField;
        }
        return field;
      });

      // NOTE: CSV exporter does not accept MONEY type
      const modifiedFields = fieldsWithOverriddenLabels?.map(field => {
        if (field.type === 'MONEY') {
          return { ...field, type: 'NUMBER' };
        }
        if (field.type === 'LOCAL_DATE') {
          return { ...field, type: 'TEXT' };
        }
        return field;
      });

      const filteredColumns = columns?.filter(field => {
        return !field || !excludedFieldsColumnsOnly?.includes(field)
      })

      const filteredFields = modifiedFields?.filter(field => {
        return !field?.name || !excludedFieldsColumnsOnly?.includes(field.name)
      })

      const exportRequest = {
        ids,
        select: filteredColumns,
        query: ids.length ? null : preparedQuery,
        metadata: {
          locale: i18n.language,
          fields: filteredFields,
        },
      };

      const setLoadingFalse = () => {
        setIsAllColumnsLoading(false);
        setIsVisibleColumnsLoading(false);
      };

      const confirmationExportColumns =
        isCheckboxChecked && confirmationCheckboxExportColumns && filteredColumns
          ? Array.from(
              new Set([
                ...filteredColumns,
                ...confirmationCheckboxExportColumns.map(
                  exportColumn => exportColumn.name
                ),
              ])
            )
          : null;

      console.log("confirmationExportColumns", confirmationExportColumns)
      const confirmationExportFields =
        isCheckboxChecked && confirmationCheckboxExportColumns && filteredFields
          ? Array.from(
              new Set([...filteredFields, ...confirmationCheckboxExportColumns])
            )
          : null;

      const confirmationCheckboxAllColumnsOrder =
        confirmationExportColumns &&
        confirmationExportFields &&
        allColumnsConfirmationExportOrder &&
        exportAllColumns
          ? { select: allColumnsConfirmationExportOrder }
          : {};

      const customAllColumnsExportOrder =
        allColumnsExportOrder && exportAllColumns
          ? { select: allColumnsExportOrder }
          : {};

      const confirmationIdFilter =
        ids.length && confirmationCheckboxIdFilterFieldName
          ? {
              ids: [],
              query: {
                ...preparedQuery,
                filters: [
                  {
                    field: confirmationCheckboxIdFilterFieldName,
                    operator: 'EQUAL',
                    values: ids,
                  },
                ],
              },
            }
          : {};

      if (buyerId && userId && recordType) {
        const exportURL =
          confirmationCheckboxURL && isCheckboxChecked
            ? confirmationCheckboxURL
            : '/api/document/export/eso';

        const selectedRecordType =
          confirmationCheckboxRecordType && isCheckboxChecked
            ? confirmationCheckboxRecordType
            : recordType;

        request('POST', exportURL)
          .send({
            buyerId,
            fileType: 'CSV',
            recordType: selectedRecordType,
            request:
              confirmationExportColumns && confirmationExportFields
                ? {
                    ...exportRequest,
                    select: confirmationExportColumns,
                    metadata: {
                      locale: i18n.language,
                      fields: confirmationExportFields,
                    },
                    ...confirmationIdFilter,
                    ...confirmationCheckboxAllColumnsOrder,
                  }
                : { ...exportRequest, ...customAllColumnsExportOrder },
            userId,
          })
          .then(res => {
            closeModal();
            confirmationCheckboxText
              ? successCallback(res, isCheckboxChecked)
              : successCallback(res);
            setLoadingFalse();
          })
          .catch(() => {
            closeModal();
            errorCallback();
            setLoadingFalse();
          });
      } else {
        const esoName = eso || configuration?.name.toLowerCase();
        const fileName = eso ? `${esoName}Data.csv` : 'exportData.csv';
        const exportURL =
          confirmationCheckboxURL && isCheckboxChecked
            ? confirmationCheckboxURL
            : `${esaUri}/export/csv`;

        const documentExportRequest =
          confirmationExportColumns && confirmationExportFields
            ? {
                ...exportRequest,
                select: confirmationExportColumns,
                metadata: {
                  locale: i18n.language,
                  fields: confirmationExportFields,
                },
                ...confirmationIdFilter,
                ...confirmationCheckboxAllColumnsOrder,
              }
            : { ...exportRequest, ...customAllColumnsExportOrder };

        request('POST', exportURL)
          .send(documentExportRequest)
          .then(response => {
            closeModal();
            if (successCallback) {
              confirmationCheckboxText
                ? successCallback(response, isCheckboxChecked)
                : successCallback(response);
            }
            exportToCSV(response.text, fileName);
            setLoadingFalse();
          })
          .catch(() => {
            closeModal();
            if (errorCallback) {
              errorCallback();
            }
            setLoadingFalse();
          });
      }
    }
  };

  return (
    <Modal
      open={showModal}
      width={600}
      onRequestClose={closeModal}
      header={t('enhancedSearch.actions.export')}
      footerActions={[
        {
          title: t('standard.cancel'),
          disabled: isVisibleColumnsLoading,
          onClick: () => {
            closeModal();
          },
          theme: 'light',
        },
        {
          title: t('enhancedSearch.actions.exportCsv'),
          disabled: isAllColumnsLoading,
          spinning: exportAllColumns
            ? isAllColumnsLoading
            : isVisibleColumnsLoading,
          onClick: () => exportColumns(exportAllColumns),
          theme: 'primary',
        },
      ]}
    >
      <p className="exportModalDescription">
        {t('enhancedSearch.actions.exportInformationDescription')}
      </p>
      {selectedCountExceedsLimit && (
        <p>
          <em>
            {t('enhancedSearch.actions.exportLimitExceeded', {
              limit: limitStr,
            })}
          </em>
        </p>
      )}
      <div
        className={cx('exportRadioGroup', {
          confirmationCheckboxMargin:
            documentExportData?.confirmationCheckboxText,
        })}
      >
        <Radio
          checked={!exportAllColumns}
          onChange={() => setExportAllColumns(false)}
          value="exportSubOptionVisibleColumns"
        >
          <p>{t('enhancedSearch.actions.visibleColumns')}</p>
        </Radio>
        <Radio
          checked={exportAllColumns}
          onChange={() => setExportAllColumns(true)}
          value="exportSubOptionAllColumns"
        >
          <p>{t('enhancedSearch.actions.allAvailableColumns')}</p>
        </Radio>
      </div>
      {documentExportData?.confirmationCheckboxText && (
        <div className="exportConfirmationCheckbox">
          <Checkbox
            checked={isCheckboxChecked}
            onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
          >
            {documentExportData.confirmationCheckboxText}
          </Checkbox>
        </div>
      )}
    </Modal>
  );
}

export default ExportModal;
